import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "What is family planning?",
    body: "Family planning refers to the various methods and strategies used to control the timing, number, and spacing of pregnancies.",
  },
  {
    id: 1,
    title: "Why is family planning important?",
    body: "Family planning can help people make informed decisions about their reproductive health, prevent unintended pregnancies, reduce the risk of complications during pregnancy and childbirth, and promote the overall health and well-being of families and communities.",
  },
  {
    id: 3,
    title: "What are the different types of family planning methods?",
    body: "There are several types of family planning methods, including hormonal methods (like the pill or the patch), barrier methods (like condoms or diaphragms), long-acting reversible contraception (like the IUD or implant), fertility awareness methods, and sterilization.",
  },
  {
    id: 4,
    title: "How effective are family planning methods?",
    body: "The effectiveness of family planning methods can vary widely depending on the method used, but many are highly effective when used correctly and consistently.",
  },
  {
    id: 5,
    title: "What are the most effective family planning methods?",
    body: "The most effective family planning methods include long-acting reversible contraception (like the IUD or implant) and sterilization.",
  },
  {
    id: 6,
    title: "Are family planning methods reversible?",
    body: "Many family planning methods are reversible, but some methods, like sterilization, are intended to be permanent.",
  },
  {
    id: 7,
    title: "Can family planning methods protect against sexually transmitted infections (STIs)?",
    body: "While some family planning methods, like condoms, can help reduce the risk of STIs, they are not always effective at preventing them.",
  },
  {
    id: 8,
    title:"How do I choose the right family planning method for me?",
    body: "Choosing the right family planning method depends on a variety of factors, including your age, health, lifestyle, and reproductive goals. It's important to discuss your options with a healthcare provider.",
  },
  {
    id: 9,
    title:"Can I use multiple family planning methods at the same time?",
    body: "Yes, some people choose to use multiple family planning methods at the same time for added protection.",
  },
  {
    id: 10,
    title: "Are there any risks associated with family planning methods?",
    body: "Like any medical intervention, family planning methods can come with potential risks and side effects. It's important to discuss these with a healthcare provider before starting any method.",
  },
  {
    id: 11,
    title:"How much does family planning cost?",
    body: "The cost of family planning methods can vary widely depending on the method used and whether or not you have health insurance coverage.",
  },
  {
    id: 12,
    title:"Are family planning methods covered by insurance?",
    body: "Many health insurance plans cover some or all of the cost of family planning methods.",
  },
  {
    id: 13,
    title:"Can teenagers use family planning methods?",
    body: "Yes, teenagers can and should have access to family planning methods if they are sexually active or planning to become sexually active.",
  },
  {
    id: 14,
    title:"Can men use family planning methods?",
    body: "Yes, there are several family planning methods available for men, including condoms, vasectomy, and withdrawal.",
  },
  {
    id: 15,
    title:"Can family planning methods affect my fertility in the future?",
    body: "While some methods, like the IUD or implant, can temporarily affect fertility after removal, they do not cause permanent infertility.",
  },
  {
    id: 16,
    title:"Can family planning methods affect my menstrual cycle?",
    body: "Yes, some family planning methods, like hormonal methods, can affect the timing and flow of your menstrual cycle.",
  },
  {
    id: 17,
    title:"How often do I need to use family planning methods?",
    body: "The frequency of use for family planning methods can vary widely depending on the method used. Some methods, like the pill, require daily use, while others, like the IUD, can last for several years.",
  },
  {
    id: 18,
    title:"How can I get access to family planning services?",
    body: "Family planning services are available through healthcare providers, community health clinics, and some pharmacies.",
  },
  {
    id: 19,
    title:"Can family planning methods be used during breastfeeding?",
    body: "Yes, many family planning methods can be safely used during breastfeeding.",
  },
  {
    id: 20,
    title:"Can family planning methods be used by people with certain medical conditions?",
    body: "Some family planning methods may not be appropriate for people with certain medical conditions.",
  },
  {
    id: 21,
    title:"What are Hormonal methods?",
    body: "These methods work by using hormones to prevent ovulation or thicken cervical mucus to block sperm from reaching an egg. Examples include the pill, patch, ring, injection, and hormonal IUD. These methods are highly effective when used correctly, but may have side effects such as nausea, headaches, or changes in menstrual cycle.",
  },
  {
    id: 22,
    title:"What are Barrier methods?",
    body: "These methods work by physically blocking sperm from entering the uterus. Examples include condoms, diaphragms, and cervical caps. Barrier methods are readily available and can help reduce the risk of STIs, but may not be as effective at preventing pregnancy as other methods.",
  },
  {
    id: 23,
    title:"What are Long-acting reversible contraception (LARC)?",
    body: "These methods are highly effective and can last for several years without requiring regular maintenance. Examples include the copper IUD, hormonal IUD, and implant. LARC methods do not require daily or regular use and can be removed at any time if the person wishes to become pregnant.",
  },
  {
    id: 24,
    title:"What are Fertility awareness methods?",
    body: "These methods involve tracking ovulation to identify when a person is most likely to conceive and avoiding unprotected sex during that time. Examples include tracking basal body temperature or cervical mucus changes. These methods require regular tracking and may not be as effective at preventing pregnancy as other methods.",
  },
  {
    id: 25,
    title:"What is Sterilization?",
    body: "These methods are intended to be permanent and involve surgical procedures to block or remove the fallopian tubes or vas deferens. Examples include tubal ligation or vasectomy. Sterilization methods are highly effective at preventing pregnancy but should be considered irreversible.",
  },
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions our clients ask about Family Planning</h1>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
